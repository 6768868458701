var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content",
    attrs: {
      "data-cy": "portfolio-assets-page"
    }
  }, [_c('div', {
    staticClass: "block block-rounded"
  }, [_c('div', {
    staticClass: "block-content pb-4"
  }, [_c('DataTable', {
    attrs: {
      "items": _vm.assets,
      "total": _vm.assets.length,
      "loading": _vm.loadingAction.listAssets,
      "columns": _vm.columns,
      "shallow-search": true
    },
    scopedSlots: _vm._u([{
      key: "siteName",
      fn: function fn(slotProps) {
        return [_c('router-link', {
          staticClass: "font-w600",
          attrs: {
            "to": {
              name: 'asset-overview',
              params: {
                id: slotProps.data._id
              }
            }
          }
        }, [_vm._v(_vm._s(slotProps.data.siteName))])];
      }
    }])
  })], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }