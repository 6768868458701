<template>
  <div data-cy="portfolio-assets-page" class="content">
    <div class="block block-rounded">
      <div class="block-content pb-4">
        <DataTable :items="assets" :total="assets.length" :loading="loadingAction.listAssets" :columns="columns" :shallow-search="true">
          <template v-slot:siteName="slotProps">
            <router-link class="font-w600" :to="{ name: 'asset-overview', params: { id: slotProps.data._id } }">{{
              slotProps.data.siteName
            }}</router-link>
          </template>
        </DataTable>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import DataTable from '@/components/DataTable';

export default {
  name: 'PortfolioAssets',
  components: {
    DataTable
  },
  data() {
    return {
      columns: [
        { name: 'Name', code: 'siteName' },
        { name: 'Floor Area', code: 'floorArea' }
      ]
    };
  },
  computed: {
    ...mapGetters({
      assets: 'assetGroup/assets',
      loadingAction: 'assetGroup/loadingAction'
    })
  }
};
</script>
